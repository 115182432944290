/* editProject */
export const SET_CACHE_PROJECT = 'SET_CACHE_PROJECT';
export const SET_SCREEN = 'SET_SCREEN';
export const SET_PROJECT = 'SET_ACTIVE_PROJECT';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_VOICE = 'SET_VOICE';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_CLIENT_SETTINGS = 'SET_CLIENT_SETTINGS';
export const UPDATE_PREVIEW_RESULT = 'UPDATE_PREVIEW_RESULT';
export const SET_IS_CHANGE = 'SET_IS_CHANGE';
export const SET_VALIDATION = 'SET_VALIDATION';
export const SET_TEMPLATE_CHANGED = 'SET_TEMPLATE_CHANGED';
export const ADD_MODULE = 'ADD_MODULE';
export const ADD_SLIDE = 'ADD_SLIDE';
export const SET_FETCH_TEMPLATE = 'SET_FETCH_TEMPLATE';
export const SET_PREVIEW = 'SET_PREVIEW';
export const SET_RECOMMENDED_TEMPLATES = ' SET_RECOMMENDED_TEMPLATES';
export const SET_CREATED = 'SET_CREATED';
