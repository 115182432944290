import {
  SET_CACHE_PROJECT,
  SET_SCREEN,
  SET_PROJECT,
  SET_CURRENT,
  SET_VOICE,
  SET_TEMPLATE,
  SET_CLIENT_SETTINGS,
  UPDATE_PREVIEW_RESULT,
  SET_IS_CHANGE,
  SET_VALIDATION,
  SET_TEMPLATE_CHANGED,
  ADD_MODULE,
  ADD_SLIDE,
  SET_FETCH_TEMPLATE,
  SET_PREVIEW,
  SET_RECOMMENDED_TEMPLATES,
  SET_CREATED,
} from './constants';

const initialState = {
  cacheProject: {},
  screen: {},
  project: {},
  current: {},
  validation: {
    text: true,
    font: true,
    voiceover: true,
    media: true,
    templates: true,
  },
  created: {
    name: '',
    moduleId: '',
    slideId: '',
    screenId: '',
  },
  voice: {
    emotion: 'Conversational',
    voiceRate: 0,
    selectedVoice: {
      voiceId: 'en-UK-juliet',
      displayName: 'Juliet (F)',
      locale: 'en-UK',
      displayLanguage: 'English',
      accent: 'UK',
      description: 'Middle-Aged',
      gender: 'Female',
      availableStyles: ['Conversational'],
    },
  },
  template: {},
  clientSettings: {},
  isChanged: false,
  isTemplateChanged: false,
  fetchTemplate: false,
  preview: '',
  suggestedTemps: [],
};

export default function editProjectReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CACHE_PROJECT: {
      return {
        ...state,
        cacheProject: action.payload,
      };
    }
    case SET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case SET_SCREEN: {
      return {
        ...state,
        screen: action.payload,
      };
    }
    case SET_CURRENT: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case SET_VOICE: {
      return {
        ...state,
        voice: action.payload,
      };
    }
    case SET_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case SET_CLIENT_SETTINGS: {
      return {
        ...state,
        clientSettings: action.payload,
      };
    }
    case SET_VALIDATION: {
      return {
        ...state,
        validation: action.payload,
      };
    }
    case SET_PREVIEW: {
      return {
        ...state,
        preview: action.payload,
      };
    }
    case SET_RECOMMENDED_TEMPLATES: {
      return {
        ...state,
        suggestedTemps: action.payload,
      };
    }

    case UPDATE_PREVIEW_RESULT: {
      const { projectId, moduleId, slideId, screenId, mediaurl } = action.payload;

      if (!state.project.ProjectData[projectId]?.Modules?.[moduleId]?.slides?.[slideId]?.Screens?.[screenId]) {
        return state;
      }

      let screenData = state.screen;

      if (state.current.moduleName === moduleId && state.current.slideName === slideId && state.current.screenName === screenId) {
        screenData['mediaurl'] = mediaurl;
      }

      state.cacheProject.ProjectData[projectId].Modules[moduleId].slides[slideId].Screens[screenId].mediaurl = mediaurl;

      return {
        ...state,
        project: {
          ...state.project,
          ProjectData: {
            ...state.project.ProjectData,
            [projectId]: {
              ...state.project.ProjectData[projectId],
              Modules: {
                ...state.project.ProjectData[projectId].Modules,
                [moduleId]: {
                  ...state.project.ProjectData[projectId].Modules[moduleId],
                  slides: {
                    ...state.project.ProjectData[projectId].Modules[moduleId].slides,
                    [slideId]: {
                      ...state.project.ProjectData[projectId].Modules[moduleId].slides[slideId],
                      Screens: {
                        ...state.project.ProjectData[projectId].Modules[moduleId].slides[slideId].Screens,
                        [screenId]: {
                          ...state.project.ProjectData[projectId].Modules[moduleId].slides[slideId].Screens[screenId],
                          mediaurl,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        screen: screenData,
        previewGeneratedOf: `${projectId}-${moduleId}-${slideId}-${screenId}`,
      };
    }

    case SET_IS_CHANGE: {
      return {
        ...state,
        isChanged: action.payload,
      };
    }
    case SET_TEMPLATE_CHANGED: {
      return {
        ...state,
        isTemplateChanged: action.payload,
      };
    }
    case SET_FETCH_TEMPLATE: {
      return {
        ...state,
        fetchTemplate: action.payload,
      };
    }
    case SET_CREATED: {
      return {
        ...state,
        created: action.payload,
      };
    }
    case ADD_MODULE:
      let activeModule = Object.keys(action.payload);
      let highlightedModule = activeModule[0];

      return {
        ...state,
        project: {
          ...state.project,
          ProjectData: {
            ...state.project.ProjectData,
            [state.project.ProjectID]: {
              ...state.project.ProjectData[state.project.ProjectID],
              Modules: {
                ...state.project.ProjectData[state.project.ProjectID].Modules,
                ...action.payload,
              },
            },
          },
        },
        current: {
          ...state.current,
          moduleName: highlightedModule,
          slideName: 'SL01',
          screenName: 'SC01.01',
          contentdescription: '',
          contenttag: '',
        },
        screen: action.payload[highlightedModule].slides['SL01'].Screens['SC01.01'],
      };

    case ADD_SLIDE:
      const SlideId = Object.keys(action.payload.slideData)[0];
      const ScreenId = Object.keys(action.payload.slideData[SlideId].Screens)[0];
      const moduleId = action.payload.moduleId;

      return {
        ...state,
        project: {
          ...state.project,
          ProjectData: {
            ...state.project.ProjectData,
            [state.project.ProjectID]: {
              ...state.project.ProjectData[state.project.ProjectID],
              Modules: {
                ...state.project.ProjectData[state.project.ProjectID].Modules,
                [moduleId]: {
                  ...state.project.ProjectData[state.project.ProjectID].Modules[moduleId],
                  slides: {
                    ...state.project.ProjectData[state.project.ProjectID].Modules[moduleId].slides,
                    ...action.payload.slideData,
                  },
                },
              },
            },
          },
        },
        current: {
          ...state.current,
          moduleName: moduleId,
          slideName: SlideId,
          screenName: ScreenId,
          contentdescription: '',
          contenttag: '',
        },
        screen: action.payload.slideData[SlideId].Screens[ScreenId],
      };

    default:
      return state;
  }
}
